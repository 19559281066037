import { number, object, string } from "yup";

import { FormDataType } from "../../components/FormDataTypes";

export const formsData: Record<string, FormDataType> = {
  FL12: {
    description: "",
    formTitle: "Earthquake",
    displayFormName: "FL-12",
    nameEdition: { name: "FL-12", edition: "11-1979" },
    inputs: [],
  },
  FLSM1: {
    description: "",
    formTitle: "Canine Exclusion",
    displayFormName: "FL-SM-1",
    nameEdition: { name: "FL-SM-1", edition: "11-1996" },
    inputs: [],
    liabilityForm: true,
  },
  ML216: {
    description: "This is a description",
    formTitle: "Protective Devices",
    displayFormName: "ML-216",
    recommended: true,
    nameEdition: { name: "ML-216", edition: "01-1987" },
    policyForm: false,
    yupShape: object().shape({
      protection: string().oneOf(["smokeDetector", "fireAlarm", "centralStation", "sprinklers"]).required(),
    }),
    inputs: [
      {
        name: "protection",
        type: "select",
        label: "Protection Device",
        options: [
          {
            value: "smokeDetector",
            text: "Fire Alarm and/or Smoke Detectors",
          },
          {
            value: "fireAlarm",
            text: "Fire and/or police department alarm systems",
          },
          {
            value: "centralStation",
            text: "Central station burglary and/or fire alarm systems",
          },
          {
            value: "sprinklers",
            text: "Sprinkler Systems",
          },
        ],
      },
    ],
    defaultInputs: {
      protection: "smokeDetector",
    },
  },
  RG1: {
    description: "",
    formTitle: "Residence Glass Endorsement",
    displayFormName: "RG-1",
    nameEdition: { name: "RG-1", edition: "01-1981" },
    inputs: [],
  },
  // SH01: {
  //   description: "",
  //   formTitle: "Student Housing Surcharge",
  //   displayFormName: "SH-01",
  //   nameEdition: { name: "SH-01", edition: "11-1996" }, // TODO Need pdf
  //   // TODO use yup for number of students dropdown... 1-6 then 7+ causes an error
  //   inputs: [],
  // },
  FL124: {
    description: "",
    formTitle: "Roof Surface ACV Loss Settlement (Windstorm or Hail)",
    displayFormName: "FL-124",
    nameEdition: { name: "FL-124", edition: "04-2014" },
    inputs: [],
  },
  SLP01: {
    description: "",
    formTitle: "Seasonal Surcharge",
    displayFormName: "SLP-01",
    nameEdition: { name: "SLP-01", edition: "11-1996" }, // TODO Need pdf
    inputs: [],
  },
  VAC01: {
    description: "",
    formTitle: "Occupancy/Vacancy Surcharge",
    displayFormName: "VAC-01",
    nameEdition: { name: "VAC-01", edition: "11-1996" }, // TODO Need pdf
    inputs: [],
  },
  FL52A: {
    description: "",
    formTitle: "Trampoline Exclusion",
    displayFormName: "FL-52A",
    recommended: true,
    nameEdition: { name: "FL-52A", edition: "11-1996" }, // TODO Need pdf
    inputs: [],
    // liabilityForm: true,
    automated: true,
  },
  NHD: {
    automated: true,
    description: "",
    formTitle: "New Home Discount",
    displayFormName: "NHD",
    nameEdition: { name: "NHD", edition: "11-1996" }, // TODO Need pdf
    inputs: [],
  },
  FL41L: {
    description: "",
    formTitle: "Additional Insured",
    displayFormName: "FL-41L",
    nameEdition: { name: "FL-41L", edition: "10-1983" },
    yupShape: object().shape({
      additionalMembers: number()
        .positive()
        .required()
        .typeError((field) => `${field.label} must be a number`)
        .label("Additional members"),
    }),
    inputs: [{ name: "additionalMembers", type: "number", label: "Additional Members", decimalScale: 0 }],
    defaultInputs: {
      additionalMembers: 1,
    },
  },
  FL72A: {
    addable: (rateOptions, location, selectedForms) => {
      if (rateOptions.locations[location].perils === "FL1R" || rateOptions.locations[location].perils === "FL1RV") {
        return false;
      }
      const exclusiveForms = ["WCIC1200"];
      if (selectedForms && selectedForms.length > 0) {
        if(selectedForms.some((form) =>  exclusiveForms.includes(form))){
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[location].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description: "",
    formTitle: "Added Water Damages",
    displayFormName: "FL-72A",
    recommended: true,
    nameEdition: { name: "FL-72A", edition: "05-2013" },
    yupShape: object().shape({
      limit: string().oneOf(["2500", "5000"]).required(),
    }),
    inputs: [
      {
        name: "limit",
        type: "select",
        label: "Limit",
        options: [
          {
            value: "2500",
            text: "$2,500",
          },
          {
            value: "5000",
            text: "$5,000",
          },
        ],
      },
    ],
    defaultInputs: {
      limit: "2500",
    },
  },
  FL342: {
    addable: (rateOptions, location, selectedForms) => {
      if (rateOptions.locations[location].perils === "FL1R" || rateOptions.locations[location].perils === "FL1RV") {
        return false;
      }
      return true;
    },
    description: "",
    formTitle: "Underground Utility Line Endorsement",
    displayFormName: "FL-342",
    recommended: true,
    nameEdition: { name: "FL-342", edition: "04-2016" },
    inputs: [],
  },
  FL345: {
    addable: (rateOptions, location, selectedForms) => {
      if (rateOptions.locations[location].perils === "FL1R" || rateOptions.locations[location].perils === "FL1RV") {
        return false;
      }
      return true;
    },
    description: "",
    formTitle: "Equipment Breakdown Enhancement Endorsement",
    displayFormName: "FL-345",
    recommended: true,
    nameEdition: { name: "FL-345", edition: "12-2017" },
    inputs: [],
  },
  WCIC1200: {
    addable: (rateOptions, location, selectedForms) => {
      if (rateOptions.locations[location].perils === "FL1R" || rateOptions.locations[location].perils === "FL1RV") {
        return false;
      }
      const exclusiveForms = ["FL72A"];
      if (selectedForms && selectedForms.length > 0) {
        if(selectedForms.some((form) =>  exclusiveForms.includes(form))){
          return false;
        }
      }
      const hasExclusivePolicyForms = rateOptions.forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      const hasExclusiveLocForms = rateOptions.locations[location].forms.some((form) => {
        return exclusiveForms!.includes(form.name);
      });
      if (hasExclusiveLocForms || hasExclusivePolicyForms) {
        return false;
      }
      return true;
    },
    description: "",
    formTitle: "Inland Flood Coverage and Added Water Damages Coverage",
    displayFormName: "WCIC1200 & WCIC1300",
    nameEdition: { name: "WCIC-1200", edition: "04-2016" }, // TODO Need to merge files, just 1200 showing
    yupShape: object().shape({
      limit: string()
        .oneOf(["5000", "10000", "15000", "20000", "25000", "30000", "40000", "50000"])
        .required()
        .label("Limit"),
    }),
    inputs: [
      {
        name: "limit",
        type: "select",
        label: "Limit",
        options: [
          {
            value: "5000",
            text: "$5,000",
          },
          {
            value: "10000",
            text: "$10,000",
          },
          {
            value: "15000",
            text: "$15,000",
          },
          {
            value: "20000",
            text: "$20,000",
          },
          {
            value: "25000",
            text: "$25,000",
          },
          {
            value: "30000",
            text: "$30,000",
          },
          {
            value: "40000",
            text: "$40,000",
          },
          {
            value: "50000",
            text: "$50,000",
          },
        ],
      },
    ],
    defaultInputs: {
      limit: "5000",
    },
  },
};
